import Cookie from "@cerebralfart/cookies";

window.Cookie = Cookie;

// Kinda an ugly hack to ensure the previous cookie consent popup's garbage is properly dealt with
try {
    for (const name in Cookie) {
    }
} catch (e) {
    // Something broke, likely multiple cookies with the same name
    // Just delete everything to be sure
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

const name = "cookie_consent_v2";
const maxAge = 365 * 24 * 60 * 60;

const popup = document.getElementById("cookie-consent");

const show = () => {
    popup.classList.remove("hidden");
    popup.animate([
        {offset: 0.0, opacity: 0, transform: "translate(0, 3rem)"},
        {offset: 1.0, opacity: 1, transform: "translate(0, 0rem)"},
    ], 500);
};
const accept = () => {
    persist({
        marketing: true,
        preferences: true,
    });
    destroy();
};
const save = () => {
    persist({
        marketing: document.querySelector("[name=cookie-consent-marketing]").value === "on",
        preferences: document.querySelector("[name=cookie-consent-preferences]").value === "on",
    });
    destroy();
};

const persist = data => Cookie[name] = new Cookie(JSON.stringify(data), {maxAge});

const destroy = () => {
    popup.animate([
        {offset: 0.0, opacity: 1, transform: "translate(0, 0rem)"},
        {offset: 1.0, opacity: 0, transform: "translate(0, 3rem)"},
    ], 500).addEventListener("finish", () => popup.remove());
};

document.getElementById("cookie-consent-accept").addEventListener("click", accept);
document.getElementById("cookie-consent-save").addEventListener("click", save);

(() => {
    if (!popup) return;
    if (name in Cookie) {
        try {
            const parsed = JSON.parse(Cookie[name]);
            if (
                typeof parsed.marketing === "boolean" &&
                typeof parsed.preferences === "boolean"
            ) {
                popup.remove();
                return;
            }
        } catch (e) {
        }
    }
    delete Cookie[name];
    show();
})();
